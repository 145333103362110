import * as Sentry from "@sentry/react";

export const updateRedirectUrlQueryParams = (
  url,
  appId,
  transactionId,
  status
) => {
  const finalStatus = status === "expired" ? "error" : status;
  try {
    const urlObject = new URL(url);
    if (urlObject.searchParams.size > 0) {
      return `${urlObject.toString()}&transactionId=${transactionId}&status=${finalStatus}`;
    }

    if (["ezs12s", "qw9rjg"].includes(appId)) {
      return `${url}?transactionId=${transactionId}&status=${finalStatus}`;
    }

    urlObject.searchParams.append("transactionId", transactionId);
    urlObject.searchParams.append("status", finalStatus);
    return urlObject.toString();
  } catch (err) {
    Sentry.captureException(err, {
      tags: {
        source: "updateRedirectUrlQueryParams",
        origin: "updateRedirectUrlQueryParams",
      },
      extra: {
        source: "invalidRedirectUrlPassed",
        appId,
        message: err.message,
        stack: err.stack,
      },
      level: "error",
    });
    throw err;
  }
};
