import { axiosWithRetry } from "./axiosRetry";

export const getKycDetails = async (identifier) => {
  const apiResponse = await axiosWithRetry({
    method: "get",
    url: `${process.env.REACT_APP_LINK_KYC_URL}/v1/link-kyc/details`,
    headers: {
      identifier,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  return apiResponse.data.result;
};

export const completeKYCFlow = async (identifier, status) => {
  const apiResponse = await axiosWithRetry({
    method: "post",
    url: `${process.env.REACT_APP_LINK_KYC_URL}/v1/link-kyc/complete`,
    data: { status, identifier },
  });
  return apiResponse.data.result;
};

export const getWebSDKVersions = async () => {
  const webSDKVersionListUrl =
    process.env.REACT_APP_WEBSDK_VERSION ||
    "https://ind-dkyc-configs.s3.ap-south-1.amazonaws.com/public/link-kyc/sdk-version.json";
  const response = await axiosWithRetry({
    method: "get",
    url: webSDKVersionListUrl,
  });
  return response.data;
};
